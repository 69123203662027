/* @jsx jsx */
import { jsx } from "theme-ui";
import { navigate } from "gatsby";
import { Layout } from "../components/Layout";
import SEO from "../components/Seo";

import { H2 } from "../components/Typography";
import { useGetCurrentUserQuery } from "../graphql/queries/_generated";
import { useEffect } from "react";
import { Flex } from "../components/Flex";
import LoginForm from "../components/LoginForm";

const RedirectToApp = () => {
  const [{ data }] = useGetCurrentUserQuery();

  useEffect(() => {
    if (data?.currentUser) {
      navigate(`/app`);
    }
  }, [data?.currentUser]);

  return null;
};

const Quote: React.FC<{}> = ({ children }) => (
  <p
    sx={{
      bg: "#FFF",
      borderRadius: "10px",
      border: "1px solid",
      textAlign: "left",
      userSelect: "text",
      width: "100%",
      overflow: "hidden",
      backfaceVisibility: "hidden",
      borderColor: "gray.3",
      boxShadow: "0 4px 5px rgba(0,0,0,0.04)",
      p: 3,
      fontStyle: `italic`,
      fontSize: 2,
      color: `gray.7`,
    }}
  >
    {children}
  </p>
);

const Testimonials = () => (
  <Flex gap={3} flexDirection="column">
    <H2 sx={{ m: 0 }}>What our customers say</H2>
    <Quote>
      “Any project that needs to start collecting feedback quickly would benefit
      immensely from Feedback Fish”
    </Quote>
    <Quote>
      “Keep up the good work, I'm a fan! We're so happy we ended up choosing
      Feedback Fish.”
    </Quote>
  </Flex>
);

export default () => {
  return (
    <Layout widgetCTA="Try the widget!">
      <SEO title="Login" description="Feedback Fish - Login" />

      <RedirectToApp />
      <Flex alignItems="center" justifyContent="center">
        <LoginForm type="sign up" />
      </Flex>
    </Layout>
  );
};
